import React from 'react'
import './NewHeader.css';
import logo from '../../images/yashvitech.png'

export default function NewHeader() {
    return (
        <div>
            <div className='headerContainer'>
                <img src={logo} alt='logo' className='newLogo'/>
                <marquee behavior="" direction="" className='blinking-text'>5 Days Demo Classes</marquee>
                <a href='https://yashvitech.com/' className='navigateHome' target='_blank'>Go to Home</a>
            </div>
        </div>
    )
}