import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NewHeader from './component/yashvitech/NewHeader'
import CourseDetail from './component/yashvitech/CourseDetail'
import ContactComp from './component/yashvitech/ContactComp'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NewHeader />
        <Routes>
          <Route element={<CourseDetail />} path="/" />
          <Route element={<ContactComp />} path="/contact" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
