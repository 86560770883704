import React, { useRef, useState } from 'react'
import './ContactForm.css'
import emailjs from '@emailjs/browser';

export default function ContactForm() {

    const form = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, subject, message } = formData;

        if (name && email && subject && message) {
            // setStatus('Form submitted successfully!');
            // setFormData({
            //     name: '',
            //     email: '',
            //     subject: '',
            //     message: ''
            // });
            // console.log(form.current)
            // --------------------------------------------------------------------------
            emailjs
                .sendForm('service_j4yexmk', 'template_ttrx203', form.current, {
                    publicKey: 'rQMCoNtuV2e0raLQF',
                })
                .then(
                    () => {
                        // setIsModalVisible(true);
                        // console.log('SUCCESS!');
                        setStatus('Form submitted successfully! Admin will contact you soon.');
                        setFormData({
                            name: '',
                            email: '',
                            subject: '',
                            message: ''
                        });
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
        } else {
            setStatus('Please fill out all fields.');
        }
    };

    return (
        <div>
            <form ref={form} onSubmit={handleSubmit} className="contact-form">
                {status && <p className="form-status">{status}</p>}
                <label htmlFor="name" className="form-label">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-input"
                    placeholder="Your Name"
                    required
                />

                <label htmlFor="email" className="form-label">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-input"
                    placeholder="Your Email"
                    required
                />

                <label htmlFor="subject" className="form-label">Subject:</label>
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="form-input"
                    placeholder="Subject"
                    required
                />

                <label htmlFor="message" className="form-label">Message:</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-textarea"
                    placeholder="Your message"
                    rows="5"
                    required
                ></textarea>

                <button type="submit" className="form-button"><b>Submit</b></button>
            </form>
        </div>
    )
}
