import React from 'react'
import './CourseDetail.css'
import { DoubleRightOutlined, CaretRightOutlined } from '@ant-design/icons'
import ContactComp from './ContactComp'

export default function CourseDetail() {
  return (
    <div className='courseComponent'>
      <h1>Training Detail</h1>
      <div className='couseDetailContainer'>
        <div className='CourseList courseListOdd'>
          <h1>Frontend Development</h1>
          <p><DoubleRightOutlined /> HTML5 Basic and advance concepts</p>
          <p><DoubleRightOutlined /> CSS3 Styling and Layouts</p>
          <p><DoubleRightOutlined /> Javascript Fundamentals</p>
          <p><DoubleRightOutlined /> React Js and React Native</p>
        </div>
        <div className='CourseList courseListEven'>
          <h1>Backend Develoment</h1>
          <p><DoubleRightOutlined /> Python Programming Fundamentals</p>
          <p><DoubleRightOutlined /> Django Framework Introduction and Setup</p>
          <p><DoubleRightOutlined /> Building Restful APIs with Django Rest Framework</p>
          <p><DoubleRightOutlined /> Database Design and Integration</p>
          <p><DoubleRightOutlined /> Authentication and authorization</p>
        </div>
        <div className='CourseList courseListEven'>
          <h1>Full Stack Development</h1>
          <p><DoubleRightOutlined /> Frontend Development with UI and UX Design</p>
          <p><DoubleRightOutlined /> BackEnd Development and Server side Code</p>
          <p><DoubleRightOutlined /> Database design and connectivity</p>
        </div>
        <div className='CourseList courseListOdd'>
          <h1>Addition Information</h1>
          <p><DoubleRightOutlined /> API Integration</p>
          <p><DoubleRightOutlined /> Work With Live Projects</p>
          <p><DoubleRightOutlined /> Mock Interviews</p>
          <p><DoubleRightOutlined /> Internship Certification</p>
          <p><DoubleRightOutlined /> Resume Building</p>
          <p><DoubleRightOutlined /> Job Placement Support</p>
        </div>
      </div>

      {/* <div className="benifitsContainer">
        <h2 className="benifitsTitle">Training Classes:</h2>
        <div className="zigzag-box">
          <p>
            Our training classes offer hands-on experience, interactive learning, and real-world scenarios designed to help you improve your skills.
          </p>
        </div>
      </div> */}
      <div className="container">
        <div className="ribbon">
          <h2 className="ribbon-title">Benifits of Training</h2>
        </div>
        <div className="info-box">
          <p><CaretRightOutlined /> Training classes provide practical, hands-on experience.</p>
          <p><CaretRightOutlined /> Tailored sessions for all experience levels.</p>
          <p><CaretRightOutlined /> Structured learning with live projects.</p>
          <p><CaretRightOutlined /> Enhance your skills and knowledge.</p>
          <p><CaretRightOutlined /> Expert instructors guide your learning process</p>
          <p><CaretRightOutlined /> Receive expert advice and feedback.</p>
        </div>
      </div>
      <ContactComp />
    </div>
  )
}