import React from 'react'
import ContactForm from './ContactForm'
import './ContactComp.css'
import { ContactsOutlined,  } from '@ant-design/icons'

export default function ContactComp() {
  return (
    <div>
      <div className='contactContainer'>
        <div className='contactDetail'>
          <p>Yashvitech IT Solution PVT. LTD.</p>
          <p>2nd Floor, Sahu Complex, Ramnagar</p>
          <p>Near Disha College, Raipur (C.G.)</p>
          <p>Mobile No.- 8640091593, 8319308575</p>
        </div>
        <div className='contactForm'>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}